import React from 'react';
import { Switch, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import AdventureBookPage from "./pages/AdventureBook";
import AboutPage from "./pages/AboutPage";
import NotFoundPage from "./pages/NotFoundPage";

import NavBar from "./components/NavBar";

const { REACT_APP_ENABLE_ADVENTURE_BOOK } = process.env;

function App() {
  return (
    <div className="App">
      <NavBar />
      <Switch>
          <Route exact path="/" component={ HomePage } />
          { REACT_APP_ENABLE_ADVENTURE_BOOK === "TRUE" && <Route path="/books/adventure" component={ AdventureBookPage } /> }
          <Route path="/about" component={AboutPage} />
          <Route component={NotFoundPage} />
        </Switch>
    </div>
  );
}

export default App;
