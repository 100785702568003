import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const SKILL_LEVELS = [
    "Skill level:",
    "Elementary",
    "Intermediate",
    "Advanced",
    "Expert"
]

const SKILLS = {
    "Languages": [
        {
            name: "Java",
            level: 80,
            yoe: 5,
        },
        {
            name: "Javascript",
            level: 80,
            yoe: 4,
        },
        {
            name: "HTML",
            level: 80,
            yoe: 4,
        },
        {
            name: "CSS",
            level: 80,
            yoe: 4,
        },
        {
            name: "Python",
            level: 55,
            yoe: 1.5,
        },
        {
            name: "SQL",
            level: 65,
            yoe: 4,
        },
        {
            name: "C++",
            level: 35,
            yoe: 1,
        },
    ],
    "Frameworks": [
        {
            name: "React.js",
            level: 80,
            yoe: 3,
        },
        {
            name: "Node.js",
            level: 75,
            yoe: 3,
        },
        {
            name: "D3.js",
            level: 60,
            yoe: 1,
        }
    ],
    "Services": [
        {
            name: "AWS",
            level: 40,
            yoe: 1,
        },
        {
            name: "Firebase",
            level: 65,
            yoe: 2,
        }
    ],
}

const INITIAL_DELAY = 0.5;
const INTERVAL_DELAY = 0.2;
let count = 0;

const Skills = () => {
    count = 0;

    return (
        <div className={ styles.skills }>
            <ul className={ styles.xAxis }>
                { _generateXAxis() }
            </ul>
            <ul className={ styles.barGraph } data-count={ count || 0 }>
                { _generateBarGraph() }
            </ul>
        </div>
    )
};

const _generateXAxis = () => {
    return SKILL_LEVELS.map((level, index) => {
        return (
            <li className={ styles.line } key={ level } style={{ left: `${ _calculateOffset(index) }%` }} >
                <span className={ styles.lineLabel }>
                    { level }
                </span>
            </li>
        )
    });
}

const _generateBarGraph = () => {
    return Object.keys(SKILLS).map((skillGroup) => {       
        return (
            <Fragment key={ skillGroup }>
                <li className={ `${ styles.skillBar } ${ styles.skillGroupTitle }` }>
                    <span className={ styles.barLabel }>{ skillGroup }</span>
                </li>
                { _generateBars(SKILLS[skillGroup]) }
            </Fragment>
        )
    })
}

const _generateBars = (skills) => {
    return skills.map((skill) => {
        count += 1;

        return (
            <li className={ styles.skillBar } key={ skill.name } style={{ width: `${ skill.level }%`, "animation-delay": `${ _calculateDelay(count) }s` }}>
                <span className={ styles.barLabel }>{ skill.name }</span>
            </li>
        )
    });
}

const _calculateDelay = (i) => {
    return INITIAL_DELAY + INTERVAL_DELAY * i;
}

const _calculateOffset = (i) => {
    if (!SKILL_LEVELS || !SKILL_LEVELS.length) {
        return 0;
    }

    return i / (SKILL_LEVELS.length - 1) * 100;
}

Skills.propTypes = {
    children: PropTypes.element.isRequired
};

export default Skills;
