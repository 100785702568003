import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

import Tabs from "../Tabs";

const ContentGrid = ({ tabs, selectedTab, updateSelectedTab, children }) => {
    return (
        <div className={ styles.contentGrid }>
            <Tabs
                tabNames={ tabs }
                selectedTab={ selectedTab }
                updateSelectedTab={ updateSelectedTab }
            />
            { children }
        </div>
    )
};

ContentGrid.propTypes = {
    tabs: PropTypes.arrayOf(PropTypes.string),
    selectedTab: PropTypes.string,
    updateSelectedTab: PropTypes.func,
    children: PropTypes.element
};

export default ContentGrid;
