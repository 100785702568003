import React from "react";
import styles from "./styles.module.scss";
import SectionWrapper from "../SectionWrapper";
import Skills from "../Skills";
import Box from "../Box";

const introText = "I'm a software engineer who loves to tackle interesting problems. I'm also a foodie who loves traveling, but is going through a quarter life crisis because of covid induced quarantines and my suddenly decreasing metabolism. While I'm stuck at home, I love playing with my 2 year old aussie-pomsky puppy.";

const AboutMe = () => {
    return (
        <div className={ styles.aboutMe }>
            <SectionWrapper title="Intro">
                <div className={ styles.wrapper }>
                    <Box subText={ introText } />
                </div>
            </ SectionWrapper>
            <SectionWrapper title="Skills">
                <Skills />
            </ SectionWrapper>
        </div>
    )
};

export default AboutMe;
