import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router"
import itineraryReducer from "./itineraryDuck";
import mapReducer from "./mapDuck";

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  itineraryReducer,
  mapReducer
});

export default rootReducer;
