/*
 * returns MONTH_NAME YYYY
 */
export function getFormattedDate(milliseconds) {
    const date = new Date(milliseconds);

    return `${ _getMonthName(date) } ${ date.getFullYear() }`;
}

/*
 * returns HH:MM
 */
export function getFormattedTime(milliseconds) {
    const date = new Date(milliseconds);

    return `${ date.getHours() }:${ _padLeadingZero(date.getMinutes()) }`;
}

// Private functions, assuming that they're only called on within this file
// and that the date obj has been created and passed to them
function _getMonthName(date) {
    return date.toLocaleString('en-us', { month: 'long' });
}

function _padLeadingZero(value) {
    return value > 9 ? value : `0${value}`;
}
