// import fetchJSON from "../../utils/fetchJSON";
import { itinerariesRef } from "../api/firebase";

// Types
export const actionTypes = {
    GET_ITINERARY_BY_ID_REQUEST: "GET_ITINERARY_BY_ID_REQUEST",
    GET_ITINERARY_BY_ID_SUCCESS: "GET_ITINERARY_BY_ID_SUCCESS",
    GET_ITINERARY_BY_ID_FAILURE: "GET_ITINERARY_BY_ID_FAILURE",
    GET_ITINERARY_BY_ID_404: "GET_ITINERARY_BY_ID_404",
    POST_DAY_ITINERARY_REQUEST: "POST_DAY_ITINERARY_REQUEST",
    POST_DAY_ITINERARY_SUCCESS: "POST_DAY_ITINERARY_SUCCESS",
    POST_DAY_ITINERARY_FAILURE: "POST_DAY_ITINERARY_FAILURE",
    DELETE_DAY_EVENT_REQUEST: "DELETE_DAY_EVENT_REQUEST",
    DELETE_DAY_EVENT_SUCCESS: "DELETE_DAY_EVENT_SUCCESS",
    DELETE_DAY_EVENT_FAILURE: "DELETE_DAY_EVENT_FAILURE",
    POST_DAY_EVENT_REQUEST: "POST_DAY_EVENT_REQUEST",
    POST_DAY_EVENT_SUCCESS: "POST_DAY_EVENT_SUCCESS",
    POST_DAY_EVENT_FAILURE: "POST_DAY_EVENT_FAILURE"
};

// Actions
// export function getItineraryById(id) {
//     return (dispatch) => {
//         dispatch({ type: actionTypes.GET_ITINERARY_BY_ID_REQUEST });

//         //TODO: replace mock api with real api
//         fetchJSON("http://www.mocky.io/v2/5d23e93c2e0000a4a2c3f223", { credentials: "include" })
//             .then(({ json }) => {                
//                 dispatch({ type: actionTypes.GET_ITINERARY_BY_ID_SUCCESS, payload: json });
//             })
//             .catch(error => {
//                 dispatch({ type: actionTypes.GET_ITINERARY_BY_ID_FAILURE, payload: error });
//             })
//     }
// }

export function getItineraryById(id) {
    return (dispatch) => {
        dispatch({ type: actionTypes.GET_ITINERARY_BY_ID_REQUEST });

        //You can only use one order-by method at a time. Calling an order-by method multiple times in the same query throws an error.
        // https://stackoverflow.com/questions/33893866/orderbychild-not-working-in-firebase
        // TODO: create a getDayItineraryById and getItinerarySummary which includes metadata (num days + default day (first for now, maybe use date detection later))
        itinerariesRef.child(id).orderByChild("startTime").on("value", snapshot => {
            const itinerary = snapshot.val();

            if (!itinerary) {
                return dispatch({
                    type: actionTypes.GET_ITINERARY_BY_ID_404,
                });
            }

            dispatch({
                type: actionTypes.GET_ITINERARY_BY_ID_SUCCESS,
                payload: {
                    id,
                    days: itinerary.days,
                }
            });
        }, error => {
            dispatch({
                type: actionTypes.GET_ITINERARY_BY_ID_FAILURE,
                error
            });
        })
    }
}

export function deleteDayEvent(id, day, eventId) {
    return (dispatch) => {
        dispatch({ type: actionTypes.DELETE_DAY_EVENT_REQUEST });

        itinerariesRef.child(`${ id }/days/${ day }/${ eventId }`).remove()
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_DAY_EVENT_SUCCESS,
                    payload: {
                        day,
                        eventId
                    }
                });
            })
            .catch(() => {
                dispatch({ type: actionTypes.DELETE_DAY_EVENT_FAILURE });
            });
    }
}

export function postDayEvent(id, day, event) {
    return (dispatch) => {
        dispatch({ type: actionTypes.POST_DAY_EVENT_REQUEST });

        //check for objectValidity
        const dayEventRef = itinerariesRef.child(`${ id }/days/${ 1 }`).push();
        
        dayEventRef.set(event, error => {
            if (error) {
                dispatch({ type: actionTypes.POST_DAY_EVENT_FAILURE });
            } else {
                dispatch({
                    type: actionTypes.POST_DAY_EVENT_SUCCESS,
                    payload: {
                        event
                    }
                });
            }
        });
    }
}

//TODO: think about this more
export function postDayItinerary(id, day, dayItinerary) {
    return (dispatch) => {
        dispatch({ type: actionTypes.POST_DAY_ITINERARY_REQUEST });

        itinerariesRef.child(`${ id }/days/${ day }`).set(dayItinerary, error => {
            if (error) {
                dispatch({ type: actionTypes.POST_DAY_ITINERARY_FAILURE });
            } else {
                dispatch({
                    type: actionTypes.POST_DAY_ITINERARY_SUCCESS,
                    payload: {
                        day,
                        dayItinerary
                    }
                });
            }
        });
    }
}

const initialState = {
    itinerary: {
        id: null,
        days: []
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.GET_ITINERARY_BY_ID_SUCCESS:
            return {
                ...state,
                itinerary: {
                    id: action.payload.id,
                    days: action.payload.days
                }
            }
        case actionTypes.DELETE_DAY_EVENT_SUCCESS:
            return {
                ...state
            }
        case actionTypes.POST_DAY_ITINERARY_SUCCESS:
            /* eslint-disable no-case-declarations */
            const days = Object.assign({}, state.itinerary.days);
            days[action.payload.day] = action.payload.dayItinerary;

            return {
                ...state,
                itinerary: {
                    ...state.itinerary,
                    days 
                }
            }
        case actionTypes.GET_ITINERARY_BY_ID_404:
        case actionTypes.GET_ITINERARY_BY_ID_FAILURE:
        case actionTypes.POST_DAY_ITINERARY_FAILURE:
            return state;
        default:
            return state;
    }
}
