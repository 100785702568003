import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import classNames from "classnames/bind";
import Button from "../Button";
import GoogleMapsSearchBox from "../GoogleMap/SearchBox";
import DateTimeSelector from "../DateTimeSelector";
import styles from "./styles.module.scss";

//CSS modules with classNames: https://github.com/JedWatson/classnames/issues/56
const cn = classNames.bind(styles);

class AddEventModal extends Component {
    constructor(props) {
        super(props);

        this.addSearchRef = this.addSearchRef.bind(this);
        this.handleSearchInputReset = this.handleSearchInputReset.bind(this);
        this.searchInputRef = null;
    }

    render() {
        const { mapsAPI } = this.props;
        const initialValues = {
            title: "",
            description: "",
            startTime: new Date(),
            endTime: new Date(Date.now() + 1000 * 60 * 60),
            location: null
        };

        return (
            <div className={ styles.addEventModalWrapper }>
                <Formik
                    initialValues={ initialValues }
                    validationSchema={Yup.object().shape({
                        title: Yup.string()
                            .required("Event Name is required"),
                        description: Yup.string(),
                        startTime: Yup.date()
                            .required("Start Time is required"),
                        endTime: Yup.date()
                            .required("End Time is required"),
                        location: Yup.object().shape({
                            name: Yup.string(),
                            lat: Yup.number(),
                            lng: Yup.number()
                        }).required("Location is required").nullable()
                    })}
                    onSubmit={(fields, { setSubmitting, resetForm }) => {
                        alert("SUCCESS!! :-)\n\n" + JSON.stringify(fields, null, 4));
                        setSubmitting(false);
                        resetForm();
                        this.handleSearchInputReset();
                    }}
                    render={({ errors, touched, isSubmitting, handleReset, dirty, submitForm, values, setFieldValue, setFieldTouched }) => {
                            const onPlacesChanged = (place) => {
                                const { name, geometry } = place;
                        
                                setFieldValue("location" ,{
                                    name,
                                    lat: geometry.location.lat(),
                                    lng: geometry.location.lng()
                                })
                            }

                            return (
                                <Form>
                                    <div className={ styles.formGroup }>
                                        <label htmlFor="title" className={ styles.inputLabel } >Event Name</label>
                                        <Field name="title" type="text" className={ cn({ formControl: true, invalidEntry: errors.title && touched.title }) } />
                                        <ErrorMessage name="title" component="div" className={ styles.invalidEntryFeedback } />
                                    </div>
                                    <div className={ cn({ formGroup: true, dateRow: true, firstDateRowElement: true }) }>
                                        <label htmlFor="startTime" className={ styles.inputLabel }>Start Time</label>
                                        <DateTimeSelector
                                            name="startTime"
                                            value={ values["startTime"] }
                                            onChange={e => setFieldValue("startTime", e)}
                                            className={ cn({ formControl: true }) }
                                        />
                                        <ErrorMessage name="startTime" component="div" className={ styles.invalidEntryFeedback } />
                                    </div>
                                    <div className={ cn({ formGroup: true, dateRow: true }) }>
                                        <label htmlFor="endTime" className={ styles.inputLabel }>End Time</label>
                                        <DateTimeSelector
                                            name="endTime"
                                            value={ values["endTime"] }
                                            onChange={e => setFieldValue("endTime", e)}
                                            className={ cn({ formControl: true }) }
                                        />
                                        <ErrorMessage name="endTime" component="div" className={ styles.invalidEntryFeedback } />
                                    </div>
                                    <div className={ styles.formGroup }>
                                        <label htmlFor="location" className={ styles.inputLabel }>Location</label>
                                        <Field name="location">
                                            {
                                                ({ form }) => {
                                                    return (
                                                    <Fragment>
                                                        <GoogleMapsSearchBox
                                                            maps={ mapsAPI }
                                                            onPlacesChanged={ onPlacesChanged }
                                                            type="text"
                                                            onBlur={ () => setFieldTouched("location", true) }
                                                            addSearchRef={ this.addSearchRef }
                                                            className={ cn({ formControl: true, invalidEntry: form.errors.location && form.errors.location.name && form.touched.location }) }
                                                        />
                                                    </Fragment>
                                                )}
                                            }
                                        </Field>
                                        <ErrorMessage name="location" component="div" className={ styles.invalidEntryFeedback } />
                                    </div>
                                    <div className={ styles.formGroup }>
                                        <label htmlFor="description" className={ styles.inputLabel }>Description</label>
                                        <Field name="description" type="text" className={ styles.formControl } />
                                    </div>
                                    <div className={ styles.formGroup }>
                                        <div className={ styles.buttonWrapper }>
                                            <Button
                                                label="Submit"
                                                type="submit"
                                                dark
                                                roundedCorners
                                                disabled={ isSubmitting }
                                                onClick={ submitForm }
                                            />
                                        </div>
                                        <div className={ styles.buttonWrapper }>
                                            <Button
                                                ref={ this.resetButton }
                                                label="Reset"
                                                type="reset"
                                                dark
                                                roundedCorners
                                                disabled={ !dirty || isSubmitting }
                                                onClick={ () => {
                                                    handleReset();
                                                    this.handleSearchInputReset();
                                                } }
                                            />
                                        </div>
                                    </div>
                                </Form>
                            );
                        }
                    }
                />
            </div>
        )
    }

    addSearchRef(ref) {
        this.searchInputRef = ref;
    }

    handleSearchInputReset() {
        if (this.searchInputRef) {
            this.searchInputRef.current.value = "";
        }
    }
}

AddEventModal.propTypes = {
    mapsAPI: PropTypes.string
};
 
export default AddEventModal;
