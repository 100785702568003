// Languages and Frameworks
export const JAVA = "Java";
export const JAVASCRIPT = "Javascript";
export const REACT = "React.js";
export const TYPESCRIPT = "Typescript";
export const HTML = "HTML";
export const CSS = "CSS";
export const NODE = "Node.js";
export const SQL = "SQL";
export const PYTHON = "Python";
export const RUBY = "Ruby";
export const RUBY_ON_RAILS = "Ruby on Rails";
export const PHP = "PHP";
export const HACK = "Hack";

// Infra
export const DYNAMODB = "AWS DynamoDB";
export const S3 = "AWS S3";
export const EC2 = "AWS EC2";
export const HADOOP = "Apache Hadoop";
export const FIREBASE = "GCP Firebase"; 
