import React, { PureComponent } from 'react';
import BreakpointWrapper from "../../components/BreakpointWrapper";
import Profile from "../../components/Profile";
import ContentGrid from "../../components/ContentGrid";
import Experience from "../../components/Experience";
import AboutMe from "../../components/AboutMe";
import Projects from "../../components/Projects";

import styles from "./styles.module.scss";

const homePageTabs = [
    "About Me",
    "Experience",
    "Projects"
]

const homePageContent = {
    "About Me": <AboutMe />,
    "Experience": <Experience />,
    "Projects": <Projects />
}

class HomePage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: homePageTabs[0]
        }

        this.updateSelectedTab = this.updateSelectedTab.bind(this);
    }

    render() {
        const { selectedTab } = this.state;

        return (
            <BreakpointWrapper>
                <div className={ styles.homePage }>
                    <Profile />
                    <ContentGrid 
                        tabs={ homePageTabs }
                        selectedTab={ selectedTab }
                        updateSelectedTab={ this.updateSelectedTab }
                    >
                        { homePageContent[selectedTab] }
                    </ContentGrid>
                </div>
            </BreakpointWrapper>
        );
    }

    updateSelectedTab(tabName) {
        this.setState({
            selectedTab: tabName
        });
    }
}

export default HomePage;
