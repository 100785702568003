import React from "react";
import styles from "./styles.module.scss";
import { BACKGROUND, PORTRAIT } from "../../../constants/pictureTypes";

import Picture from "../Picture";

const Profile = () => {
    return (
        <div className={ styles.profile }>
            <div className={ styles.backgroundPicture }>
                <div className={ styles.opaqueFilter } />
                <Picture
                    caption="Marina Bay Sands"
                    imgSrc="https://danshen-dev.s3.us-east-2.amazonaws.com/website/mbs.jpg"
                    isBackground
                    pictureType={ BACKGROUND }
                />
            </div>
            <div className={ styles.profilePicture }>
                <Picture
                    caption="Dan Shen"
                    captionAlignment="center"
                    captionSize="large"
                    pictureType={ PORTRAIT }
                    imgSrc="https://danshen-dev.s3.us-east-2.amazonaws.com/website/me.jpg"
                />
            </div>
        </div>
    )
};

export default Profile;
