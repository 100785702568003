import React, { Component } from 'react';
import PropTypes from "prop-types";
import GoogleMapReact from 'google-map-react';
// import SearchBox from './SearchBox';
 
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const API_KEY = process.env.GOOGLE_MAPS_API_KEY;

class SimpleMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            places: []
        }
        this.handleApiLoaded = this.handleApiLoaded.bind(this);
    }

    static defaultProps = {
        center: {
            lat: 40.7411,
            lng: -73.9897
        },
        zoom: 14
    };

    handleApiLoaded = (map, maps) => {
        const { setMapsAPI } = this.props;

        setMapsAPI(maps);
    };

    onPlacesChanged = (place) => {
        const { places } = this.state;

        this.setState({
            places: [...places, place]
        });
    }
    
    render() {
        const { places } = this.state;
        const { center, zoom } = this.props;
        
        return (
        // Important! Always set the container height explicitly
        <div style={{ height: 'calc(100vh - 54px)', width: '100%' }}>
            {/* { enableSearch ? <SearchBox maps={ mapsAPI } onPlacesChanged={ this.onPlacesChanged } /> : null } */}
            <GoogleMapReact
                bootstrapURLKeys={{ key: API_KEY }}
                defaultCenter={ center }
                defaultZoom={ zoom }
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
            >
                { places.map(this.generateMapMarker) }
            </GoogleMapReact>
        </div>
        );
    }

    generateMapMarker = (place) => {
        const { name, geometry, place_id } = place;
        const lat = geometry.location.lat();
        const lng = geometry.location.lng();

        return (         
            <AnyReactComponent
                key={ place_id }
                lat={ lat }
                lng={ lng }
                text={ name }
            />
        )
    }
}

SimpleMap.propTypes = {
    enableSearch: PropTypes.bool,
    mapsAPI: PropTypes.string,
    center: PropTypes.object,
    zoom: PropTypes.number,
    setMapsAPI: PropTypes.bool
};

AnyReactComponent.propTypes = {
    text: PropTypes.string
};
 
export default SimpleMap;
