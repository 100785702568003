import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import styles from "./styles.module.scss";
import Itinerary from "../../components/Itinerary";
import Button from "../../components/Button";
import AddEventModal from "../../components/AddEventModal";
import { HOUR_MINUTE } from "../../../constants/dateTypes";
import {
    getItineraryById,
    postDayItinerary,
    postDayEvent,
    deleteDayEvent
} from "../../reduxDucks/itineraryDuck";

export class ItineraryContainer extends React.Component {
    constructor(props) {
        super(props);

        this.handleAddToDayItinerary = this.handleAddToDayItinerary.bind(this);
        this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
    }
    render() {
        const { itinerary, mapsAPI } = this.props;

        return (
            <React.Fragment>
                <Itinerary
                    todaysItinerary={ itinerary.days[0] }
                    handleDeleteEvent={ this.handleDeleteEvent }
                    enableOptions
                    timestampFormat={ HOUR_MINUTE }
                    orderOldestToRecent
                />
                <div className={ styles.buttonWrapper }>
                    <Button
                        label="+"
                        circle
                        light
                        medium
                        onClick={ this.handleAddToDayItinerary }
                    />
                </div>
                <AddEventModal mapsAPI={ mapsAPI }/>
            </React.Fragment>
        );
    }

    componentDidMount() {
        const { getItineraryById } = this.props;

        getItineraryById("testItinerary");
    }

    handleAddToDayItinerary() {
        const { itinerary, postDayEvent } = this.props;
        const mockDay = 0;
        const mockEvent = {
            "description" : "Meeting with Nancy again!! 2",
            "endTime" : Date.now(),
            "location" : {
              "lat" : 40.727566,
              "lng" : -73.98380499999996,
              "name" : "Thursday Kitchen"
            },
            "startTime" :  Date.now() - 100000,
            "title" : "Morning Noms"
        }

        if (!itinerary.days[mockDay]) {
            return;
        }

        // postDayItinerary("testItinerary", mockDay, [...itinerary.days[mockDay], mockEvent]);

        postDayEvent("testItinerary", mockDay, mockEvent);
    }

    handleDeleteEvent(eventId) {
        const { itinerary, deleteDayEvent } = this.props;

        const mockDay = 0;

        if (!itinerary.days[mockDay]) {
            return;
        }

        deleteDayEvent("testItinerary", mockDay, eventId);
    }
}

function mapStateToProps(state) {
    return {
        itinerary: state.itineraryReducer.itinerary,
        mapsAPI: state.mapReducer.googleMaps.api
    };
}

function mapDispatchToProps(dispatch) {
    //    actions: bindActionCreators(actions, dispatch)
    return {
        deleteDayEvent: (id, day, eventId) => dispatch(deleteDayEvent(id, day, eventId)),
        postDayItinerary: (id, day, event) => dispatch(postDayItinerary(id, day, event)),
        postDayEvent: (id, day, event) => dispatch(postDayEvent(id, day, event)),
        getItineraryById: (id) => dispatch(getItineraryById(id))
    };
}

ItineraryContainer.propTypes = {
    itinerary: PropTypes.array,
    mapsAPI: PropTypes.string,
    getItineraryById: PropTypes.func,
    deleteDayEvent: PropTypes.func,
    postDayEvent: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItineraryContainer);
