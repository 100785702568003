import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

import Icon from "../Icon";

const Button = (props) => {
    const {
        onClick,
        label,
        disabled,
        type,
        icon,
        link
    } = props;

    const classes = manageStyles(props);
    const onClickHandler = disabled
        ? () => { return }
        : onClick;

    return (
        <div
            type={ type }
            disabled={ disabled }
            onClick={ onClickHandler }
            className={ classes }
            tabIndex="0"
        >
            { getButtonContent({ icon, link, label }) }
        </div>
    );
};

const getButtonContent = ({ icon, link, label }) => {
    const innerContent = icon
        ? <Icon type={ icon } />
        : label;

    if (link) {
        return (
            <a className={ styles.buttonLink } href={ link } target="_blank" rel="noopener noreferrer">
                { innerContent }
            </a>
        )
    }

    return innerContent;
}

const manageStyles = (props) => {
    const propNames = Object.keys(props);

    return propNames
        .reduce((accumulator, prop) => {
            if (prop === "icon") {
                return props[prop] && styles[props[prop]]
                    ? [...accumulator, styles[props[prop]]]
                    : accumulator;
            }

            return props[prop] && styles[prop]
                ? [...accumulator, styles[prop]]
                : accumulator;
        }, [
            styles.button
        ])
        .join(" ");
}

Button.defaultProps = {
    disabled: false,
    types: "button",
    block: false
}

Button.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string
};

export default Button;
