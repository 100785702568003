import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const Box = (props) => {
    const {
        text,
        subText,
        footer
    } = props;

    return (
        <div className={ styles.box }>
            <div className={ styles.text }>{ text }</div>
            <div className={ styles.subText }>{ subText }</div>
            {
                footer &&
                <div className={ styles.footer }>
                    { footer }
                </div>
            }
        </div>
    )
};

Box.propTypes = {
    text: PropTypes.string.isRequired,
    subText: PropTypes.string,
    footer: PropTypes.element
};

export default Box;
