import React, { useState } from "react";
import PropTypes from "prop-types";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
    KeyboardDatePicker ,
    KeyboardTimePicker,
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";

function DateTimeSelector({ useDate, useTime, ...restProps }) {
    if (!(useDate || useTime)) {
        return null;
    }

    /* eslint-disable react-hooks/rules-of-hooks */
    const [selectedDate, handleDateChange] = useState(new Date());
    const props = Object.assign(
        {
            value: selectedDate,
            onChange: handleDateChange,
            allowKeyboardControl: true,
            openTo: "hours",
            minutesStep: 5,
            disablePast: true,
        }, restProps);


    const picker = useDate && useTime
        ? (<KeyboardDateTimePicker { ...props } />)
        : useDate
            ? (<KeyboardDatePicker { ...props } />)
            : (<KeyboardTimePicker { ...props } />)


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            { picker }
        </MuiPickersUtilsProvider>
    );
}

DateTimeSelector.defaultProps = {
    useDate: false,
    useTime: true
}

DateTimeSelector.propTypes = {
    useDate: PropTypes.bool.isRequired,
    useTime: PropTypes.bool.isRequired
};

export default DateTimeSelector;
