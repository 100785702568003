import React from 'react';
/* eslint-disable import/no-named-as-default */
import GoogleMapContainer from "../../containers/GoogleMapContainer";
import ItineraryContainer from "../../containers/ItineraryContainer";
import styles from "./styles.module.scss";

class AdventureBook extends React.Component {
    render() {
        return (
            <div className={ styles.adventureBook }>
                <div className={ styles.listWrapper }>
                    <ItineraryContainer />
                </div>
                <div className={ styles.mapWrapper }>
                    <GoogleMapContainer />
                </div>
            </div>
        );
    }
}

export default AdventureBook;
