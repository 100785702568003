import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import {
    GITHUB,
    LINKEDIN,
    INSTAGRAM,
    EDIT
} from "../../../constants/iconTypes";

import { ReactComponent as EditSvg } from "../../assets/pencil.svg";
import { ReactComponent as GithubSvg } from "../../assets/github.svg";
import { ReactComponent as LinkedinSvg } from "../../assets/linkedin.svg";
import { ReactComponent as InstagramSvg } from "../../assets/instagram.svg";

const AVAILABLE_ICONS = {
    [GITHUB]: <GithubSvg />,
    [LINKEDIN]: <LinkedinSvg />,
    [INSTAGRAM]: <InstagramSvg />,
    [EDIT]: <EditSvg />
};

const Icon = ({ type }) => {
    if (!AVAILABLE_ICONS[type]) {
        return;
    }

    return (
        <div className={ styles.icon }>
            { AVAILABLE_ICONS[type] }
        </div>
    )
};

Icon.defaultProps = {

};

Icon.propTypes = {
    type: PropTypes.string.isRequired,
};

export default Icon;
