import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { BACKGROUND, PORTRAIT } from "../../../constants/pictureTypes";

const cn = classNames.bind(styles);

const Picture = ({ caption, captionAlignment, captionSize, imgSrc, pictureType }) => {
    const captionStyles = `${ styles.caption } ${ styles[captionAlignment] } ${ styles[captionSize] }`;
    const size = pictureType === BACKGROUND ? "1200" : "300";

    return (
        <div className={ styles.pictureWrapper }>
            <div className={ cn({ picture: true, portraitWrapper: pictureType === PORTRAIT }) }>
                { imgSrc && <img className={ cn({ pictureContent: true, background: pictureType === BACKGROUND }) }  src={ imgSrc } alt={ `Image of ${ caption }` } width={ size }></img> }
            </div>
            { caption && 
                <div className={ captionStyles }>
                    { caption }
                </div>
            }
        </div>
    )
};

Picture.defaultProps = {
    captionAlignment: "left",
    captionSize: "medium",
    rounded: false,
    bordered: false
};

Picture.propTypes = {
    caption: PropTypes.string,
    captionAlignment: PropTypes.string,
    captionSize: PropTypes.string,
    imgSrc: PropTypes.string,
    pictureType: PropTypes.string
};

export default Picture;
