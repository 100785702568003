import React from 'react';
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import ItineraryEvent from "./Event";

class Itinerary extends React.Component {
    render() {
        const {
            todaysItinerary,
            handleDeleteEvent,
            enableOptions,
            timestampFormat,
            titleSize,
            orderOldestToRecent,
            generateFooter
        } = this.props;

        if (enableOptions && !handleDeleteEvent) {
            return "";
        }

        return (
            <ul className={ styles.eventList }>
                { Object.entries(todaysItinerary).map(([eventId, eventData]) => this.generateEvent(eventId, eventData, handleDeleteEvent, enableOptions, timestampFormat, titleSize, orderOldestToRecent, generateFooter)) }
            </ul>
        );
    }

    generateEvent(eventId, eventData, handleDeleteEvent, enableOptions, timestampFormat, titleSize, orderOldestToRecent, generateFooter) {
        return (
            <ItineraryEvent
                key={ eventId }
                event={ eventData }
                handleDeleteEvent={ handleDeleteEvent }
                eventId={ eventId }
                enableOptions={ enableOptions }
                timestampFormat={ timestampFormat }
                titleSize={ titleSize }
                orderOldestToRecent={ orderOldestToRecent }
                footer={ this._generateFooter(eventData, generateFooter) }
            />
        );
    }

    _generateFooter(eventData, generateFooter) {
        if (!generateFooter) {
            return null;  
        }

        return generateFooter(eventData);
    }
}

Itinerary.defaultProps = {
    todaysItinerary: []
}

Itinerary.propTypes = {
    todaysItinerary: PropTypes.array,
    handleDeleteEvent: PropTypes.func,
    enableOptions: PropTypes.bool,
    timestampFormat: PropTypes.string,
    titleSize: PropTypes.string,
    orderOldestToRecent: PropTypes.bool
}

export default Itinerary;
