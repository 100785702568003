export const actionTypes = {
    SET_MAP_API: "SET_MAP_API"
};

// Actions
export function setMapsAPI(apiKey) {
    return (dispatch) => dispatch({ type: actionTypes.SET_MAP_API, payload: apiKey });
}

const initialState = {
    googleMaps: {
        api: null
    }
};

// Reducer
export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case actionTypes.SET_MAP_API:
            return {
                ...state,
                googleMaps: {
                    api: action.payload
                }
            };
        default:
            return state;
    }
}
