import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const BreakpointWrapper = (props) => {
    return (
        <div className={ styles.breakpointWrapper }>
            { props.children }
        </div>
    )
};

BreakpointWrapper.propTypes = {
    children: PropTypes.element.isRequired
};

export default BreakpointWrapper;
