import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";

import { GITHUB, LINKEDIN, INSTAGRAM } from "../../../constants/iconTypes";

import BreakpointWrapper from "../BreakpointWrapper";
import Button from "../Button";

const { REACT_APP_ENABLE_ADVENTURE_BOOK } = process.env;

const navItemConfigs = [
    {
        destination: "/",
        isExactDestination: true,
        name: "Home",
        enabled: true
    },
    {
        destination: "/books/adventure",
        isExactDestination: false,
        name: "Adventure Book",
        enabled: REACT_APP_ENABLE_ADVENTURE_BOOK === "TRUE"
    }
];

const SOCIAL_BUTTONS = [
    {
        icon: GITHUB,
        label: "Github Repo",
        url: "https://github.com/dshen36/"
    },
    {
        icon: LINKEDIN,
        label: "LinkedIn Profile",
        url: "https://www.linkedin.com/in/danshen/"
    },
    {
        icon: INSTAGRAM,
        label: "Royce's Instagram",
        url: "https://www.instagram.com/smol_royce/"
    }
];

class NavBar extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            underBarStyles: {}
        };

        this.navWrapperRef = React.createRef();
    }

    render() {
        const { underBarStyles } = this.state;

        return (
            <div className={ styles.navBar }>
                <BreakpointWrapper>
                    <div className={ styles.navBarWrapper } ref={ this.navWrapperRef }>
                        { this._generateLinks(navItemConfigs) }
                        <div className={ styles.underBar } style={ underBarStyles } />
                    </div>
                    <div className={ styles.socialButtons }>
                        { this._generateSocialButtons() }
                    </div>
                </BreakpointWrapper>
            </div>
        );
    }

    _generateLinks = (navItemConfigs) => {
        return navItemConfigs.map((navItemConfig) => (
            <NavItem
                key={ navItemConfig.destination }
                navItemConfig={ navItemConfig }
                onElementFocus={ this.onElementFocus }
                onElementBlur={ this.onElementBlur }
            />
        ));
    };

    onElementFocus = (event) => {
        this._handleUnderBar(event);
    }

    onElementBlur = (event) => {
        this.setState({
            underBarStyles: {}
        });
    }

    _handleUnderBar = (event) => {
        const navWrapperLeftOffset = this.navWrapperRef.current.getBoundingClientRect().left;
        const navElementDimensions = event.target.getBoundingClientRect();

        this.setState({
            underBarStyles: {
                width: `${ navElementDimensions.width }px`,
                left: `${ navElementDimensions.left - navWrapperLeftOffset }px`,
                background: "#1997C6",
            }
        });
    }

    _generateSocialButtons = () => {
        return SOCIAL_BUTTONS.map((socialButton) => {
            const {
                icon,
                label,
                url
            } = socialButton;
            
            return (                
                <div className={ styles.socialButton } key={ label }>
                    <Button
                        icon={ icon }
                        link={ url }
                        xsmall
                        circle
                        dark
                    />
                </div>
            );
        })
    }
}

// Including in here to achieve floating
class NavItem extends PureComponent {
    render() {
        const { navItemConfig } = this.props;
        const {
            destination,
            isExactDestination,
            name,
            enabled
        } = navItemConfig;
    
        if (!enabled) {
            return "";
        }
    
        return (
            <NavLink
                className={ styles.link }
                exact={ isExactDestination }
                to={ destination }
                activeClassName={ styles.active }
                onMouseEnter={ this.onMouseEnter }
                onMouseLeave={ this.onMouseLeave }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }>
                    
                    { name }
            </NavLink>
        );
    }

    onMouseEnter = (event) => {
        const { onElementFocus } = this.props;

        onElementFocus(event);
    }

    onMouseLeave = (event) => {
        const { onElementBlur } = this.props;

        onElementBlur(event);
    }

    onFocus = (event) => {
        const { onElementFocus } = this.props;

        onElementFocus(event);
    }

    onBlur = (event) => {
        const { onElementBlur } = this.props;

        onElementBlur(event);
    }
}

NavItem.propTypes = {
    navItemConfig: PropTypes.objectOf({
        name: PropTypes.string,
        destination: PropTypes.string,
        isExactDestination: PropTypes.bool
    })
}

export default NavBar;
