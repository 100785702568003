import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import Box from "../Box";
import Button from "../Button";

const PROJECTS = [
    {
        title: "Website",
        description: "An isomorphic application powered by node.js and react.js to host information about me.",
        codeUrl: "https://github.com/dshen36/website-v2"
    }
]

const Projects = () => {
    return (
        <div className={ styles.projects }>
            { _generateProjects() }
        </div>
    )
};

const _generateProjects = () => {
    return PROJECTS.map((project) => {
        const {
            title,
            description,
            codeUrl,
            demoUrl
        } = project;

        const footer = (
            <div className={ styles.projectFooter }>
                { codeUrl &&
                    (<div className={ styles.projectButton }>
                        <Button
                            small
                            roundedCorners
                            dark
                            className={ styles.projectButton }
                            link={ codeUrl }
                            label="Source Code"
                        />
                    </div>)
                }
                { demoUrl &&
                    (<div className={ styles.projectButton }>
                        <Button
                            small
                            roundedCorners
                            dark
                            className={ styles.projectButton }
                            link={ demoUrl }
                            label="Demo"
                        />
                    </div>)
                }
            </div>
        )

        return (
            <div className={ styles.project } key={ title }>
                <Box
                    text={ title }
                    subText={ description }
                    footer={ footer }
                />
            </div>
        )
    });
};

Projects.propTypes = {
    text: PropTypes.string
};

export default Projects;
