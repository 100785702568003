import React, { Component } from "react";
import styles from "./styles.module.scss";

import Itinerary from "../Itinerary";
import Button from "../Button";
import { MONTH_YEAR, NOW } from "../../../constants/dateTypes";
import {
    JAVA,
    JAVASCRIPT,
    REACT,
    TYPESCRIPT,
    HTML,
    CSS,
    NODE,
    SQL,
    PYTHON,
    RUBY,
    RUBY_ON_RAILS,
    PHP,
    HACK,
    DYNAMODB,
    S3,
    EC2,
    HADOOP,
    FIREBASE
} from "../../../constants/techStack";

const DEBOUNCE_TIMEOUT = 300; //ms
const EXPERIENCE = [
    {
        title: "Facebook",
        startTime: 1592798400000,
        endTime: NOW,
        location: {
            name: "New York City, NY"
        },
        description: "SDE 2 at Facebook",
        skills: [
            JAVASCRIPT,
            CSS,
            PYTHON,
            PHP,
            HACK
        ]
    },
    {
        title: "Bloomberg L.P",
        startTime: 1505707200000,
        endTime: 1589694901082,
        location: {
            name: "New York City, NY"
        },
        description: "SDE 1 at Bloomberg",
        skills: [
            JAVA,
            JAVASCRIPT,
            REACT,
            TYPESCRIPT,
            HTML,
            CSS,
            NODE,
            SQL,
            S3
        ]
    },
    {
        title: "Georgia Institute of Technology",
        startTime: 1377144000000,
        endTime: 1493870400000,
        location: {
            name: "Atlanta, GA"
        },
        description: "Graduated Summa Cum Laude at Georgia Tech with B.S in Computer Science",
        skills: [
            JAVA,
            JAVASCRIPT,
            HTML,
            CSS,
            SQL,
            PYTHON,
            FIREBASE
        ]
    },
    {
        title: "Amazon",
        startTime: 1462420800000,
        endTime: 1470369600000,
        location: {
            name: "Seattle, WA"
        },
        description: "SDE Intern at Amazon",
        skills: [
            JAVASCRIPT,
            HTML,
            CSS,
            SQL,
            RUBY,
            RUBY_ON_RAILS,
            S3,
            EC2,
            DYNAMODB
        ]
    },
    {
        title: "Comcast",
        startTime: 1430798400000,
        endTime: 1438747200000,
        location: {
            name: "Denver, CO"
        },
        description: "Big Data SDE Intern at Comcast",
        skills: [
            JAVA,
            JAVASCRIPT,
            HTML,
            CSS,
            SQL,
            HADOOP
        ]
    }
];

class Experience extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTechStack: null,
            filteredExperience: EXPERIENCE
        };

        this.searchInputRef = React.createRef();
        this.debounceTimer = null;
    }

    render() {
        const { selectedTechStack, filteredExperience } = this.state;

        return (
            <div className={ styles.experience }>
                <div className={ styles.search }>
                    <input
                        ref={ this.searchInputRef }
                        className={ styles.searchField }
                        type="text"
                        placeholder="Search a skill"
                        value={ selectedTechStack || "" }
                        onChange={ this.onInputChange }
                    />
                    <Button
                        label="Clear"
                        red
                        roundedCorners
                        small
                        onClick={ this.clearInput }
                    />
                </div>
                {
                    filteredExperience.length > 0
                    ? (
                        <Itinerary
                            todaysItinerary={ filteredExperience }
                            timestampFormat={ MONTH_YEAR } titleSize={ "large" }
                            generateFooter={ (eventData) => this._generateFooter(eventData, selectedTechStack) }
                        />
                    )
                    : (<div className={ styles.noResults }>No experience found</div>)
                }
            </div>
        )
    }

    clearInput = () => {
        this.setState({
            selectedTechStack: null,
            filteredExperience: EXPERIENCE
        });
    }

    _generateFooter = (eventData, selectedTechStack) => {
        const skills = eventData.skills;
        const upperCasedSelectedTechStack = selectedTechStack === null ? null : selectedTechStack.toUpperCase();
    
        if (!skills || skills.length === 0) {
            return null;
        }
    
        return (
            <div className={ styles.experienceFooter }>
                <div className={ styles.footerTitle }>Tech Stack</div>
                <div className={ styles.footerContent }>
                    { skills.map((skill) => {
                        const isSelected = skill.toUpperCase().includes(upperCasedSelectedTechStack);

                        return (
                            <div className={ styles.skillWrapper } key={ `${ eventData.title }-${ skill }` }>
                                <Button
                                    label={ skill }
                                    roundedCorners
                                    blue={ isSelected }
                                    light={ !isSelected }
                                    small
                                    onClick={ () => this.skillButtonOnClick(skill) }
                                />
                            </div>
                        );
                    }) }
                </div>
            </div>
        )
    }

    skillButtonOnClick = (techSkill) => {
        this.setState({
            selectedTechStack: techSkill
        });
        this.filterExperience(techSkill);
    }

    onInputChange = () => {
        if (this.debounceTimer) {
            clearTimeout(this.debounceTimer);
        }

        const searchQuery = this.searchInputRef.current.value;

        this.setState({
            selectedTechStack: searchQuery === "" ? null : searchQuery
        });

        this.debounceTimer = setTimeout(() => {
            const searchQuery = this.searchInputRef.current.value;

            this.filterExperience(searchQuery);
        }, DEBOUNCE_TIMEOUT);
    }

    filterExperience = (query) => {        
        if (!query) {
            this.setState({
                filteredExperience: EXPERIENCE
            });

            return;
        }

        const upperCasedQuery = query.toUpperCase();
        const filteredExperience = EXPERIENCE.filter((exp) => {
            return exp.skills.some((skill) => skill.toUpperCase().includes(upperCasedQuery));
        });

        this.setState({
            filteredExperience
        });
    }
}

export default Experience;
