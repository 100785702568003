import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cn = classNames.bind(styles);

class Tabs extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            underBarStyles: {}
        };

        this.tabsWrapperRef = React.createRef();
    }

    render() {
        const { tabNames, selectedTab, updateSelectedTab } = this.props;
        const { underBarStyles } = this.state;

        if (!tabNames) {
            return "";
        }
    
        return (
            <div className={ styles.tabs }>
                <div className={ styles.tabsWrapper } ref={ this.tabsWrapperRef }>
                    { this._generateTabs(tabNames, selectedTab, updateSelectedTab) }
                    <div className={ styles.underBar } style={ underBarStyles }/>
                </div>
            </div>
        )
    }

    _generateTabs = (tabNames, selectedTab, updateSelectedTab) => {
        return tabNames.map((tabName) => {
            return (
                <Tab
                    tabName={ tabName }
                    selectedTab={ selectedTab }
                    key={ tabName }
                    updateSelectedTab={ updateSelectedTab }
                    onElementFocus={ this.onElementFocus }
                    onElementBlur={ this.onElementBlur }
                />
            );
        });
    }

    onElementFocus = (event) => {
        this._handleUnderBar(event);
    }

    onElementBlur = (event) => {
        this.setState({
            underBarStyles: {}
        });
    }

    _handleUnderBar = (event) => {
        const tabWrapperLeftOffset = this.tabsWrapperRef.current.getBoundingClientRect().left;
        const tabElementDimensions = event.target.getBoundingClientRect();

        this.setState({
            underBarStyles: {
                width: `${ tabElementDimensions.width }px`,
                left: `${ tabElementDimensions.left - tabWrapperLeftOffset }px`,
                background: "#1997C6",
            }
        });
    }

    //Todo
    _handleResize = () => {

    }

    componentDidMount() {
        this._handleResize();
    }

    componentWillUnmount() {
        this._handleResize()
    }
}

class Tab extends PureComponent {
    render() {
        const { tabName, selectedTab } = this.props;

        return (
            <button
                type="button"
                className={ cn({ tab: true, selected: selectedTab === tabName }) }
                onClick={ this.onClick }
                onMouseEnter={ this.onMouseEnter }
                onMouseLeave={ this.onMouseLeave }
                onFocus={ this.onFocus }
                onBlur={ this.onBlur }
            >
                { tabName }
            </button>
        );
    }

    onClick = () => {
        const { updateSelectedTab, tabName } = this.props;

        updateSelectedTab(tabName);
    }

    onMouseEnter = (event) => {
        const { onElementFocus } = this.props;

        onElementFocus(event);
    }

    onMouseLeave = (event) => {
        const { onElementBlur } = this.props;

        onElementBlur(event);
    }

    onFocus = (event) => {
        const { onElementFocus } = this.props;

        onElementFocus(event);
    }

    onBlur = (event) => {
        const { onElementBlur } = this.props;

        onElementBlur(event);
    }
}

Tabs.propTypes = {
    tabNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedTab: PropTypes.string.isRequired,
    updateSelectedTab: PropTypes.func.isRequired
};

Tab.propTypes = {
    tabName: PropTypes.string.isRequired,
    selectedTab: PropTypes.string.isRequired,
    updateSelectedTab: PropTypes.func.isRequired
}

export default Tabs;
