import React, { Fragment } from 'react';
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { getFormattedDate, getFormattedTime } from "../../../../utils/dates";
import { HOUR_MINUTE, MONTH_YEAR, NOW } from "../../../../constants/dateTypes";
import Button from "../../Button";

//CSS modules with classNames: https://github.com/JedWatson/classnames/issues/56
const cn = classNames.bind(styles);

const timestampFormaters = {
    [HOUR_MINUTE]: getFormattedTime,
    [MONTH_YEAR]: getFormattedDate
}

class Event extends React.Component {
    constructor(props) {
        super(props);

        this.editEvent = this.editEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
    }

    render() {
        const { event, enableOptions, titleSize, footer } = this.props;
        const { title, description, location } = event;

        return (
            <li className={ styles.event }>
                <span className={ styles.line } />
                <div className={ styles.eventDetails }>
                    <div className={ `${ styles.eventName } ${ styles[titleSize] }`}>{ title }</div>
                    <div className={ styles.eventLocation }>{ location.name }</div>
                    <div className={ styles.eventNotes }>{ description }</div>
                    { footer }
                </div>
                <div className={ cn({ options: true, enabled: enableOptions }) }>
                    <div className={ styles.buttonWrapper }>
                        <Button
                            label="x"
                            circle
                            red
                            xsmall
                            onClick={ this.deleteEvent }
                        />
                    </div>
                    <div className={ styles.buttonWrapper }>
                        <Button
                            icon="edit"
                            circle
                            light
                            xxsmall
                            onClick={ this.editEvent }
                        />
                    </div>
                </div>
                <span className={ styles.time }>
                    { this.getTimeStampOrder() }
                </span>
            </li>
        );
    }

    getTimeStampOrder() {
        const { orderOldestToRecent, timestampFormat, event } = this.props;
        const { startTime, endTime } = event;
        const timestampFormater = timestampFormaters[timestampFormat] || timestampFormaters[HOUR_MINUTE];

        const formattedStartTime = timestampFormater(startTime);
        const formattedEndTime = endTime === NOW
            ? "Current"
            : timestampFormater(endTime);

        if (orderOldestToRecent) {
            return (
                <Fragment>
                    <div className={ `${ styles.timeTop } ${ styles[timestampFormat] }` }>{ formattedStartTime }</div>
                    <div className={ `${ styles.timeBottom } ${ styles[timestampFormat] }` }>{ formattedEndTime }</div>
                </Fragment>
            );
        }

        return (
            <Fragment>
                <div className={ `${ styles.timeTop } ${ styles[timestampFormat] }` }>{ formattedEndTime }</div>
                <div className={ `${ styles.timeBottom } ${ styles[timestampFormat] }` }>{ formattedStartTime }</div>
            </Fragment>
        )
    }

    editEvent() {
        /* eslint-disable no-console */
        console.log("EDITED EVENT");
    }

    deleteEvent() {
        const { handleDeleteEvent, eventId } = this.props;

        handleDeleteEvent(eventId);
    }
}

Event.defaultProps = {
    enableOptions: false,
    orderOldestToRecent: false
};

Event.propTypes = {
    event: PropTypes.object,
    enableOptions: PropTypes.bool,
    titleSize: PropTypes.string,
    orderOldestToRecent: PropTypes.bool,
    timestampFormat: PropTypes.string,
    handleDeleteEvent: PropTypes.func,
    eventId: PropTypes.string
}

export default Event;
