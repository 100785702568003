import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import GoogleMap from "../../components/GoogleMap";
import {
    setMapsAPI
} from "../../reduxDucks/mapDuck";

export class GoogleMapContainer extends React.Component {
    render() {
        const { setMapsAPI, mapsAPI } = this.props;

        return (
            <React.Fragment>
                <GoogleMap
                    enableSearch
                    setMapsAPI={ setMapsAPI }
                    mapsAPI={ mapsAPI }
                />
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        mapsAPI: state.mapReducer.googleMaps.api
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setMapsAPI: (apiKey) => dispatch(setMapsAPI(apiKey)),
    };
}

GoogleMapContainer.propTypes = {
    setMapsAPI: PropTypes.func,
    mapsAPI: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GoogleMapContainer);
