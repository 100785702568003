import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";

const SectionWrapper = (props) => {
    const {
        children,
        title
    } = props;

    return (
        <div className={ styles.sectionWrapper }>
            <h2 className={ styles.sectionTitle }>
                <span className={ styles.title }>{ title }</span>
            </h2>
            { children }
        </div>
    )
};

SectionWrapper.propTypes = {
    children: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired
};

export default SectionWrapper;
