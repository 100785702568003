import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";

class SearchBox extends React.Component {
    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.searchBox = null;
    }

    render() {
        return <input ref={ this.inputRef } { ...this.props } type="text"/>;
    }

    onPlacesChanged = () => {
        const { onPlacesChanged } = this.props;

        if (onPlacesChanged) {
            const places = this.searchBox.getPlaces();

            if (places.length) {
                onPlacesChanged(places[0]);
            }
        }
    }

    componentDidMount() {
        const { addSearchRef } = this.props;

        if (addSearchRef) {
            addSearchRef(this.inputRef);
        }
    }

    componentDidUpdate() {
        const { maps } = this.props;

        if (!this.searchBox && maps) {
            /* eslint-disable react/no-find-dom-node */
            var input = ReactDOM.findDOMNode(this.inputRef.current); 
            this.searchBox = new maps.places.SearchBox(input);
            this.searchBox.addListener('places_changed', this.onPlacesChanged);
        }
    }
    
    componentWillUnmount() {
        const { maps } = this.props;

        maps.event.clearInstanceListeners(this.searchBox);
    }
}

SearchBox.propTypes = {
    maps: PropTypes.object,
    onPlacesChanged: PropTypes.func,
    addSearchRef: PropTypes.func
};

export default SearchBox;
